import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import Question from '../components/question'
import Footer from '../components/footer'
import Header from '../components/header'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Sekkur</title>
        <meta property="og:title" content="Sekkur" />
      </Helmet>

      <Header/>

      <div className="home-hero">
        <div className="home-hero1">
          <div className="home-container01">
            <h1 className="home-hero-heading heading1">
              Überlassen Sie das Risiko von Cyberangriffen auf Ihr Unternehmen nicht dem Zufall
            </h1>
            <span className="home-hero-sub-heading">
              Holen Sie sich jetzt Ihren kostenlosen Cyber Gesundheits Check
            </span>
            <div className="home-btn-group">
              <Link to="/register" className="home-hero-button1 button">
                Jetzt loslegen
              </Link>
              <Link to="/register" className="home-hero-button2 button">
                Erfahren Sie mehr →
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="home-details1">
        <div className="home-steps">
          <h1 className="home-text">
            <span>Unser Ansatz</span>
          </h1>
          <div className="home-container02">
            <div className="home-container03">
              <div className="home-step">
                <h1 className="home-text02">
                  <span>1</span>
                </h1>
                <h1 className="home-text04">Direkte Einsicht</h1>
                <span className="home-text05">
                  Geben Sie den Namen Ihrer Website ein und erhalten Sie sofort Ergebnisse.
                </span>
              </div>
              <div className="home-step">
                <h1 className="home-text06">
                  <span>2</span>
                </h1>
                <h1 className="home-text08">Risiko Verstehen</h1>
                <span className="home-text05">
                  <span>
                    Leicht verständliche Einblicke, für deren Entschlüsselung Sie keinen IT-Abschluss brauchen...
                  </span>
                  <br></br>
                </span>
              </div>

              <div className="home-step">
                <h1 className="home-text12">
                  <span>3</span>
                </h1>
                <h1 className="home-text14">Bedrohungen Stoppen</h1>
                <span className="home-text05">
                  Für jedes identifizierte Cyber-Risiko geben wir Ihnen eine jargonfreie Zusammenfassung,
                  und wir zeigen wie Sie damit umzugehen haben.
                </span>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className="home-details2">
        <div className="home-details3">
          <div className="home-container06">
            <div className="home-container07">
              <span className="home-text16 sectionTitle">
                <span>Details</span>
                <br></br>
              </span>
              <h2 className="home-details-heading heading2">
                Übernehmen Sie die Kontrolle
                <br></br>
                über Ihre Cyberrisiken mit Sekkur
              </h2>
              <span className="home-details-sub-heading">
                Entdecken Sie, wie unsere innovative Technologie Ihr Unternehmen schützen kann
              </span>
            </div>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1584433144760-1946bb52e9a4?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTI2NDY5OHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            className="home-details-image"
          />
        </div>
      </div>
      <div className="home-details4"></div>
      <div className="home-features">
        <div className="home-features-container">
          <div className="home-features1">
            <div className="home-container08">
              <span className="home-text19 sectionTitle">
                <span>services</span>
                <br></br>
              </span>
              <h2 className="home-features-heading heading2">
                Cyber-Risiko-Tools für jedes Unternehmen
              </h2>
              <span className="home-features-sub-heading">
                Entdecken Sie die Leistungsfähigkeit unserer Cybersicherheits-Management-Software
              </span>
            </div>
            <div className="home-container09">
              <FeatureCard
                Heading="Ein Cyber Gesundheits Check mit Analyse und Empfehlungen"
                SubHeading="Decken Sie die Schwachstellen Ihres Unternehmens mit diesem einfachen, eigenständigen Bericht über Cyberrisiken auf. Er ist in wenigen Minuten erstellt und in einer für Sie verständlichen Sprache verfasst und bietet einen Einblick in die Cyberrisiken Ihres Unternehmens. Außerdem enthält er eine nach Prioritäten geordnete Liste mit empfohlenen Maßnahmen zur Behebung der festgestellten Probleme."
              ></FeatureCard>
              <FeatureCard
                Heading="Kontinuierliche Überwachung und Warnmeldungen"
                SubHeading="Cyber-Kriminelle machen keinen Urlaub, und unsere Überwachung auch nicht. Es überwacht kontinuierlich die Cyberlandschaft und warnt Sie vor potenziellen neuen Risiken, sobald diese auftauchen. Über ein dynamisches, interaktives Dashboard können Sie unser Premium-Cyber-Risikopaket für Unternehmen abrufen."
              ></FeatureCard>
              <FeatureCard
                Heading="Leistungsstarke Domainüberwachung"
                SubHeading="Stoppen Sie mit Domain Watch die Verletzung von Markendomains. Unser leistungsstarker Domain-Überwachungsdienst hält Ausschau nach Domain-Typo-Squatting, E-Mail-Phishing, Website-Spoofing und allem anderen, was die Web-Domain und den Ruf Ihrer Marke gefährdet."
              ></FeatureCard>
              <FeatureCard
                Heading="24/7 Reaktion auf Cybervorfälle unseres Teams"
                SubHeading="Unser Support-Team kann Ihnen dabei helfen, einen schwerwiegenden Cyberangriff schnell zu beheben, damit Sie Ihr Geschäft wieder in Gang bringen können. Unser 24/7-Forensik- und Wiederherstellungsteam wird mit Ihnen zusammenarbeiten, um sicherzustellen, dass die Krise eingedämmt wird und Sie weitere Datengefährdung, Rufschädigung und finanzielle Verluste vermeiden."
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-pricing"></div>
      <div className="home-banner">
        <div className="home-banner1">
          <h1 className="home-banner-heading heading2">
            Schützen Sie Ihr Unternehmen vor Cyberangriffen
          </h1>
          <span className="home-banner-sub-heading">
            Schwachstellen aufdecken und umsetzbare Empfehlungen erhalten
          </span>
          <Link to="/register" className="home-banner-button button">
            Kostenloser Cyber Gesundheits Check
          </Link>
        </div>
      </div>
      <div className="home-faq">
        <div className="home-faq-container">
          <div className="home-faq1">
            <div className="home-container10">
              <span className="home-text22 sectionTitle">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text25 heading2">Häufige Fragen</h2>
              <span className="home-text26">
                <span>
                  Hier sind einige der häufigsten Fragen, die wir erhalten.
                </span>
                <br></br>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container11">
              <Question
                Answer="Cybersicherheits-Gesundheits-Check ist eine umfassende Bewertung des Cyberrisikos Ihrer Organisation. Er hilft dabei, Schwachstellen zu identifizieren und Empfehlungen zu geben, um diese zu beheben."
                Question="Was ist ein Cybersicherheits-Gesundheits-Check?"
              ></Question>
              <Question
                Answer="Der Cyber-Risiko-Bericht wird innerhalb weniger Minuten nach Abschluss des Cyber-Gesundsheit-Checks geliefert."
                Question="Wie lange dauert es, den Cyber-Risiko-Bericht zu erhalten?"
              ></Question>
              <Question
                Answer="Der Cyber-Risiko-Bericht enthält eine Analyse der Schwachstellen Ihrer Organisation, verfasst in einer für Sie verständlichen Sprache. Er bietet auch eine priorisierte Liste von empfohlenen Maßnahmen zur Behebung der identifizierten Probleme."
                Question="Was beinhaltet der Cyber-Risiko-Bericht?"
              ></Question>
              <Question
                Answer="Kontinuierliches Monitoring und Alarme ist eine Funktion, die die sich ständig entwickelnde Cyber-Landschaft im Auge behält und Sie vor möglichen neuen Risiken warnt, wenn sie auftauchen. Es bietet Echtzeit-Updates und kann über ein interaktives Dashboard abgerufen werden."
                Question="Was ist kontinuierliches Monitoring und Alarme?"
              ></Question>
              <Question
                Answer="Das Domain-Monitoring ist ein Service, der dazu beiträgt, die Webdomain und den Ruf Ihrer Marke zu schützen. Es überwacht Domain-Typo-Squatting, E-Mail-Phishing, Website-Spoofing und andere Bedrohungen, die Ihre Marke gefährden könnten."
                Question="Was ist Domain-Monitoring?"
              ></Question>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>
  )
}

export default Home
